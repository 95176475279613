export type Nullable<T> = T | null;

export enum Locale {
  De = 'DE',
  En = 'EN',
  Fr = 'FR',
  It = 'IT'
}

export interface Client {
  [key: string]: unknown;

  id: number;
  name: string;
  messaging_token: string;
  last_contact_at: string;
  current_user_id: number;
  phone_number: string;
  is_connected: boolean;
  unique_id?: string;
  session_id?: string;
  secret?: string;
  created_at: string;
}

export interface Group {
  id: number;
  name: string;
  main_group_id: number | null;
  call_handling_mode: CallHandlingMode;
  section_id: number;
}

export interface Profile {
  id: number;
  name: string;
  primary_node_ids: number[];
  secondary_node_ids: number[];
}

export interface SystemTreeNode {
  id: number;
  kind: string;
  parent_id: number;
  text_short: string;
  text_long: string;
  call_type_text: string;
  call_type_color: string;
  device_number?: number;
  section_id?: number;
  sort_order: number;
  urgency: string;
  children: SystemTreeNode[];
}

export interface Section {
  id: number;
  name: string;
  sort_order: number;
}

export interface User {
  id: number;
  name: string;
  current_display_name: string;
  unique_id: string;
  muted_until: string | null;
  nodes_priority: number[];
  section_calltypes: Record<string, number[]>
  last_contact_at: string;
  app_user_group_id: number;
  status: string;
  in_call_since: string | null;
  created_at: string;
}

export interface UserClientCall {
  user: User
  client: Client
  call: Pick<Call, 'call_type_color' | 'opened_at' | 'text_short' | 'text_long'>
}

export interface Timeouts {
  relevant_calls: number;
  escalation: number;
  resend: number;
  release: number;
}

export enum CallUrgency {
  Normal = 'Normal',
  Urgent = 'Urgent',
  Emergency = 'Emergency',
}

export interface Call {
  id: number;
  upstream_id: number;
  text_short: string;
  text_long: string;
  section_name: string;
  position: string;
  urgency: CallUrgency;
  is_presence: boolean;
  call_type_text: string;
  call_type_color: string;
  call_type_sort_order: number;
  call_source_char: string;
  call_source_text: string;
  call_source_number: number;
  nodes: number[];
  created_at: string;
  opened_at: string;
  closed_at: string | null;
  resent_at: string | null;
  resend_count: number;
  accepted_at: string | null;
  accepted_by_user_id: number | null;
  accepted_by_username: string | null;
  escalates_at: string | null;
  escalated_at: string | null;
  escalation_count: number;
  releases_at: string | null;
  released_at: string | null;

  pending_users: number[] | null;
  notified_users: number[] | null;
  notified_sections: number[];
  display_group_id: number;
}

export enum CallAction {
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Escalated = 'Escalated',
  Released = 'Released',
  Resent = 'Resent',
  Muted = 'Muted',
}

export interface AppCallAction {
  id: number
  client_id: string
  username: string
  call_id: number
  action: CallAction
  created_at: string
}

export interface License {
  id: number;
  name: string;
  features: {
    app: boolean
  };
  limits: {
    app_clients: number
  };
}

export interface Alert {
  id: number;
  upstream_id: number;
  title: string;
  body: string;
  class: string;
  category: string;
  created_at: string;
  updated_at: string;
  closed_at: string | null;
}

export enum NotificationTopic {
  CALL = 'call'
}

export interface NotificationData {
  topic: NotificationTopic;
  data: {
    title: string;
    body: string;
    channel: string;
    timestamp: number;
    expires: number;
    call_id?: string;
    open_calls_ids?: string;
    notification_id?: string;
    calls_count?: string;
    urgency?: string;
  }
}

export enum CallHandlingMode {
  Full = 'Full',
  RejectOnly = 'RejectOnly'
}

export interface DisplayName {
  display_name: string;
  created_at: string;
  last_used_at: string;
}

export interface Features {
  display_names: boolean;
  presences: boolean;
  team_view: boolean;
  logout_disabled: boolean;
  subscriptions: boolean;
  subscriptions_user_defined: boolean;
  subscriptions_confirmation_required: boolean;
  version: number;
}