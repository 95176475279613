import { acceptCall, getCalls, muteCall, rejectCall, releaseCall } from '@/api/requests'
import { useTime } from '@/common/time'
import { Undoable } from '@/common/undo'
import { State } from '@/store/index'
import { Call, CallHandlingMode, User } from '@/types'
import { Action } from 'vuex'
import { CallGrouper } from '@/common/call.api'
import { ConflictError } from '@/common/errors'

// noop is a placeholder function that does nothing.
const noop = () => {
}

export enum CallSection {
  Own = 'own',
  New = 'new',
  Others = 'others',
  Presences = 'presences',
  Muted = 'muted',
  Closed = 'closed',
}

export interface CallState {
  // calls contains all open calls in the system.
  calls: Call[]
  // rejectedCalls contains all calls that have been rejected.
  rejectedCalls: Record<number, string>
  // releasedCalls contains all calls that have been released for other cliens.
  releasedCalls: Record<number, string>
  // undo is the function to undo a pending action.
  undo: CallableFunction
  // pendingActions is used to keep track of the number of pending undoable actions.
  pendingActions: number
  // lastUpdate is the time of the last calls update.
  lastUpdate: string
}
const state: CallState = {
  calls: [],
  rejectedCalls: {},
  releasedCalls: {},
  undo: noop,
  pendingActions: 0,
  lastUpdate: '',
}

// Sort by call type sort order, then by escalation, then by oldest first.
const defaultCallSorter = (a: Call, b: Call) => {
  // Call Type
  if (a.call_type_sort_order > b.call_type_sort_order) {
    return 1
  }
  if (b.call_type_sort_order > a.call_type_sort_order) {
    return -1
  }

  // Escalation
  if (a.escalated_at === null && b.escalated_at !== null) {
    return 1
  }
  if (a.escalated_at !== null && b.escalated_at === null) {
    return -1
  }

  // oldest first
  return a.id - b.id
}

// groupCallsForClient groups all available calls into subgroups for a given user.
export function groupCallsForUser (calls: Call[], user: User, mode: CallHandlingMode, usePriorities: boolean, serverVersion: number) {
  const grouper = new CallGrouper(user, mode, state, usePriorities, serverVersion)

  const grouped = grouper.group(calls)

  // Sort the calls.
  grouped.new.sort(defaultCallSorter)
  grouped.others.sort(defaultCallSorter)
  grouped.muted.sort(defaultCallSorter)
  // Sort presences by newest first.
  grouped.presences.sort((a, b) => a.id - b.id)
  // Sort closed calls by newest first.
  grouped.closed.sort((a, b) => (+new Date(b.closed_at ?? 0)) - (+new Date(a.closed_at ?? 0)))
  // Sort own by call type order and id.
  grouped.own.sort((a, b) => a.call_type_sort_order - b.call_type_sort_order || a.id - b.id)

  return grouped
}

const getters = {
  byId: (state: CallState) => (id: number) => {
    return state.calls.find(c => c.id === id)
  },
  groupedForUser: (state: CallState, getters: any, rootState: State) => (user: User, mode: CallHandlingMode) => {
    return groupCallsForUser(state.calls, user, mode, rootState.app.features.subscriptions, rootState.app.features.version)
  },
}

const actions: Record<string, Action<CallState, State>> = {
  async fetch ({ commit, state }) {
    // Don't fetch new call state if there are still local actions pending.
    if (state.pendingActions > 0) {
      return
    }
    const response = await getCalls()
    if (response) {
      commit('set', { calls: response.calls })
      commit('setRejected', response.rejected)
      commit('setReleased', response.released)

      if (response.alerts) {
        commit('alert/setAlerts', response.alerts, { root: true })
      }
      if (response.confirmed_alerts) {
        commit('alert/setConfirmedAlerts', response.confirmed_alerts, { root: true })
      }
    }
  },

  async accept ({ commit, getters, dispatch }, payload: { id: number, username: string, userId: string, finally: CallableFunction }) {
    const now = useTime()

    const undoable = new Undoable()
    commit('setUndo', undoable.undo.bind(undoable))
    commit('increasePendingActions')

    const call = getters['byId'](payload.id)
    commit('patch', {
      id: payload.id,
      call: {
        ...call,
        accepted_at: new Date(now.value.now).toISOString(),
        accepted_by_username: payload.username,
        accepted_by_user_id: payload.userId
      }
    })

    // resolve is run when all actions are done.
    let resolve = () => {
    }

    return undoable.run(async () => {
      try {
        const response = await acceptCall(payload.id)
        commit('set', { calls: response.calls, force: true })
        return response
      } catch (e: any) {
        if (e instanceof ConflictError) {
          resolve = () => {
            dispatch('handleConflict', { ...(e.data as object) })
          }
          return
        }
        throw e
      }
    }, () => {
      commit('patch', { id: call.id, call })
    }, () => {
      commit('setUndo', noop)
      commit('reducePendingActions')
      payload.finally()
      resolve()
    })
  },

  async reject ({ commit, getters }, payload: { id: number, finally: CallableFunction }) {
    const undoable = new Undoable()
    commit('setUndo', undoable.undo.bind(undoable))
    commit('increasePendingActions')

    const call = getters['byId'](payload.id)
    commit('remove', payload.id)
    commit('addRejectedCall', call)

    // Trigger a state refresh.
    commit('patch', { id: payload.id, call: { ...call } })

    return undoable.run(async () => {
      const response = await rejectCall(payload.id)
      commit('set', { calls: response.calls, force: true })
      return response
    }, () => {
      commit('add', call)
      commit('removeRejectedCall', call)
    }, () => {
      commit('setUndo', noop)
      commit('reducePendingActions')
      payload.finally()
    })
  },

  async mute ({ commit, getters }, payload: { id: number, finally: CallableFunction }) {
    // Muted calls are handled as rejected calls client-side.
    const undoable = new Undoable()
    commit('setUndo', undoable.undo.bind(undoable))
    commit('increasePendingActions')

    const call = getters['byId'](payload.id)
    commit('addRejectedCall', call)

    // Trigger a state refresh.
    commit('patch', { id: payload.id, call: { ...call } })

    return undoable.run(async () => {
      const response = await muteCall(payload.id)
      commit('set', { calls: response.calls, force: true })
      return response
    }, () => {
      commit('removeRejectedCall', call)
    }, () => {
      commit('setUndo', noop)
      commit('reducePendingActions')
      payload.finally()
    })
  },

  async release ({ commit, getters }, payload: { id: number, finally: CallableFunction }) {
    const undoable = new Undoable()
    commit('setUndo', undoable.undo.bind(undoable))
    commit('increasePendingActions')

    const call = getters['byId'](payload.id)
    commit('patch', {
      id: payload.id,
      call: { ...call, accepted_at: null, accepted_by_username: null, accepted_by_user_id: null }
    })
    commit('addReleasedCall', call)

    return undoable.run(async () => {
      const response = await releaseCall(payload.id)
      commit('set', { calls: response.calls, force: true })
      return response
    }, () => {
      commit('patch', { id: payload.id, call })
      commit('removeReleasedCall', call)
    }, () => {
      commit('setUndo', noop)
      commit('reducePendingActions')
      payload.finally()
    })
  },

  async handleConflict ({ dispatch }, { owner }) {
    let message
    if (owner) {
      message = `Ruf wurde von ${owner.name} bereits übernommen.`
    } else {
      message = 'Der Ruf wurde bereits von jemandem übernommen.'
    }

    const hideSnackbar = await dispatch('ui/showSnackbar', { message }, { root: true })
    setTimeout(hideSnackbar, 10000)

    await dispatch('fetch')
  },
}

const mutations = {
  set (state: CallState, payload: { calls: Call[], force?: boolean }) {
    // Only replace the state if there are no pending actions.
    // This makes sure we don't use state from the server that might not have
    // received any pending local actions yet. Since we apply all actions
    // locally immediately, the server state is always behind if there
    // are multiple actions pending.
    if (payload.force || state.pendingActions == 0) {
      state.calls = payload.calls
      state.lastUpdate = new Date().toISOString()
    }
  },
  setRejected (state: CallState, calls: Record<number, string>) {
    for (const id in calls) {
      state.rejectedCalls[id] = calls[id]
    }
  },
  setReleased (state: CallState, calls: Record<number, string>) {
    for (const id in calls) {
      state.releasedCalls[id] = calls[id]
    }
  },
  setUndo (state: CallState, undo: CallableFunction) {
    state.undo = undo
  },
  add (state: CallState, call: Call) {
    state.calls.push(call)
  },
  addRejectedCall (state: CallState, call: Call) {
    state.rejectedCalls[call.id] = (new Date).toISOString()
  },
  removeRejectedCall (state: CallState, call: Call) {
    delete state.rejectedCalls[call.id]
  },
  addReleasedCall (state: CallState, call: Call) {
    state.releasedCalls[call.id] = (new Date).toISOString()
  },
  removeReleasedCall (state: CallState, call: Call) {
    delete state.releasedCalls[call.id]
  },
  remove (state: CallState, id: number) {
    const index = state.calls.findIndex(c => c.id === id)
    state.calls.splice(index, 1)
  },
  patch (state: CallState, payload: { id: number, call: Call }) {
    const index = state.calls.findIndex(c => c.id === payload.id)
    state.calls.splice(index, 1, payload.call)
  },
  reducePendingActions (state: CallState) {
    state.pendingActions -= 1
  },
  increasePendingActions (state: CallState) {
    state.pendingActions += 1
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
