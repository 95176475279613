import { SystemTreeNode } from '@/types'

const logPrefix = '[CareSuite App] '

export function logInfo (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.info(logPrefix + message, ...context)
}

export function log (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.log(logPrefix + message, ...context)
}

export function logTrace (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.trace(logPrefix + message, ...context)
}

export function logError (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.error(logPrefix + message, ...context)
}

export function logWarning (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.warn(logPrefix + message, ...context)
}

export function logDebug (message: string, ...context: any[]) {
  // eslint-disable-next-line no-console
  console.debug(logPrefix + message, ...context)
}

// Set --vh CSS variable to real viewport height (including address bar).
export function setVH () {
  const vh = (window as any).innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export function flattenTree (nodes: SystemTreeNode[] | undefined) {
  const flatNodes: SystemTreeNode[] = []

  nodes?.forEach(node => {
    flatNodes.push(node)

    if (node.children) {
      flatNodes.push(...flattenTree(node.children))
    }
  })

  return flatNodes
}

export function setCookie (name: string, value: string, expDays: number) {
  const date = new Date()
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + '; ' + expires + '; path=/'
}

export function getCookie (cName: string) {
  const name = cName + '='
  const cDecoded = decodeURIComponent(document.cookie) //to be careful
  const cArr = cDecoded.split('; ')
  let res
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length)
  })
  return res
}

// removeHash removes the hash from the URL.
export function removeHash () {
  history.pushState('', document.title, window.location.pathname + window.location.search)
}

export type ThrottledFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;
export function throttle<T extends (...args: any) => any>(func: T, limit: number): ThrottledFunction<T> {
  let inThrottle: boolean
  let lastResult: ReturnType<T>

  return function (this: any, ...args: any): ReturnType<T> {
    if (!inThrottle) {
      inThrottle = true

      setTimeout(() => (inThrottle = false), limit)

      lastResult = func.apply(this, args)
    }

    return lastResult
  }
}

// throttleWithBackoff increases the delay with each call to the function.
export function throttleWithBackoff (fn: (...args: any) => any, delay: number, maxDelay: number) {
  let shouldWait = false
  let waitingArgs: any
  let timesCalled = 0

  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false
      timesCalled = 0
    } else {
      fn(...waitingArgs)
      waitingArgs = null
      const backoff = Math.min(timesCalled, 5)
      const useDelay = Math.min(maxDelay, 2 ** backoff * delay)
      setTimeout(timeoutFunc, useDelay)
    }
  }

  return (...args: any) => {
    if (shouldWait) {
      timesCalled++
      waitingArgs = args
      return
    }

    fn(...args)
    shouldWait = true
    setTimeout(timeoutFunc, delay)
  }
}
