import { patchUser } from '@/api/requests'
import { ifNative, State as StatePlugin, WebSocket } from '@/capacitor'
import { storageGet, storageSet } from '@/bootstrap/storage'
import { formatTime, parseISO, useTime } from '@/common/time'
import { logDebug } from '@/common/utils'
import { State } from '@/store/index'
import { CallHandlingMode, Group, User } from '@/types'
import { Action, Store } from 'vuex'

export interface UserState {
  user: User
  group: Group
}

const userData: User = {
  id: 0,
  name: 'Unregistered',
  current_display_name: '',
  muted_until: null,
  unique_id: '',
  last_contact_at: '',
  app_user_group_id: 0,
  status: 'Idle',
  created_at: '',
  in_call_since: null,
  nodes_priority: [],
  section_calltypes: {},
}

const groupData: Group = {
  id: 0,
  name: '',
  section_id: -1,
  main_group_id: null,
  call_handling_mode: CallHandlingMode.Full,
}

const state: UserState = {
  user: userData,
  group: groupData,
}

// Storage key for the capacitor SharedStorage plugin.
const sharedStorageUserStateKey = 'ch.caresuite.app.user'

// restoreClientState restores the client state from localStorage.
export async function restoreUserState (store: Store<State>) {
  const storedState = await storageGet(sharedStorageUserStateKey)
  if (storedState) {
    const data = JSON.parse(storedState) as UserState
    if (data && data.user) {
      await store.dispatch('user/setUserAndGroup', { user: data.user, group: data.group, skipPersisting: true })
    }
  }
}

export async function updateStoredState (state: UserState) {
  await storageSet(sharedStorageUserStateKey, JSON.stringify(state))
  ifNative(async () => {
    await StatePlugin.refresh()
  })
}

const getters = {
  mutedUntil: (state: UserState) => {
    const time = useTime()
    const mutedUntil = state.user.muted_until ? parseISO(state.user.muted_until).getTime() : 0

    const difference = mutedUntil - time.value.now
    if (difference < 0) {
      return null
    }

    return Math.ceil(difference / (60 * 1000))
  },

  mutedUntilTime: (state: UserState) => {
    return state.user.muted_until ? formatTime(parseISO(state.user.muted_until)) : ''
  }
}

const mutations = {
  setUserAndGroup (state: UserState, payload: { user: User, group: Group }) {
    if (!Array.isArray(payload.user.nodes_priority)) {
      payload.user.nodes_priority = []
    }

    if (payload.user) {
      state.user = { ...state.user, ...payload.user }
    }

    if (state.group) {
      state.group = { ...state.group, ...payload.group }
    }
  },
  setNodesPriority (state: UserState, nodes: number[]) {
    state.user.nodes_priority = nodes
  },
  clearUser (state: UserState) {
    state.user = {
      id: 0,
      current_display_name: '',
      nodes_priority: [],
      section_calltypes: {},
      name: '',
      status: 'Idle',
      created_at: '',
      last_contact_at: '',
      in_call_since: null,
      muted_until: '',
      unique_id: '',
      app_user_group_id: 0,
    }
    state.group = {
      id: 0,
      name: '',
      section_id: -1,
      main_group_id: null,
      call_handling_mode: CallHandlingMode.Full,
    }
  },
  // Caution: Use the action to persist this change to the storage.
  setCurrentDisplayName (state: UserState, currentDisplayName: string) {
    state.user.current_display_name = currentDisplayName
  },
}

const actions: Record<string, Action<UserState, State>> = {
  async setUserAndGroup ({ commit, state }, payload: { user: User, group: Group, skipPersisting?: boolean }) {
    commit('setUserAndGroup', payload)

    logDebug('User state updated', JSON.stringify(state) as any)
    if (!payload.skipPersisting && state.user) {
      await updateStoredState(state)
    }
  },
  async clearUser ({ commit, state }) {
    commit('clearUser')

    await updateStoredState(state)
  },
  async setCurrentDisplayName ({ state, commit }, currentDisplayName: string) {
    commit('setCurrentDisplayName', currentDisplayName)

    await updateStoredState(state)
    await WebSocket.rebuildNotifications()
  },
  async muteNotifications ({ commit }, duration: number) {
    const newUser = (await patchUser({
      muted_until: duration
    })).user

    commit('setUserAndGroup', { user: newUser })

    await updateStoredState(state)
    await WebSocket.rebuildNotifications()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
