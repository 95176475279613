<script setup lang="ts">
  import Modal from '@/components/modal/Modal.vue'
  import TextButton from '@/components/material/TextButton.vue'

  const emit = defineEmits(['close', 'confirm'])
  defineProps({
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    actionLabel: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: '',
    },
  })

  async function confirm () {
    emit('confirm')
    emit('close')
  }
</script>

<template>
  <Modal :title="title" :visible="visible">
    <div class="mb-4 text-sm">
      <slot>
        {{ text }}
      </slot>
    </div>

    <template #actions>
      <TextButton class="text-csBlue-800" @click="$emit('close')">
        {{ cancelLabel ? cancelLabel : $t('common.actions.cancel') }}
      </TextButton>
      <TextButton class="text-csBlue-800 ml-3" @click="confirm">
        {{ actionLabel ? actionLabel : $t('common.actions.confirm') }}
      </TextButton>
    </template>
  </Modal>
</template>

