<script setup lang="ts">
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import TextButton from '@/components/material/TextButton.vue'

  const store = useStore()

  const snackbars = computed(() => store.state.ui.snackbars)

  function reset (key: string) {
    store.commit('ui/resetSnackbar', key)
  }
</script>

<template>
  <teleport to="body">
    <div class="z-50 max-w-3xl mx-auto fixed left-0 right-0 bottom-0 snackbar-container space-y-2">
      <transition-group name="zoom">
        <div v-for="(snackbar, key) in snackbars" :key="key" class="snackbar">
          <div
            class="bg-gray-800 text-white text-sm mx-4 rounded px-4 py-[8px] min-h-[46px] flex items-center justify-between"
            @click="reset(key)"
          >
            <div class="flex justify-between items-center flex-1 leading-snug">
              <p class="hyphens-auto">
                {{ snackbar.message }}
              </p>

              <div v-if="snackbar.action" class="ml-2 actions text-yellow-300 cursor-pointer">
                <TextButton v-if="snackbar.actionFn" @click="snackbar.actionFn as any">
                  {{ snackbar.action }}
                </TextButton>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </teleport>
</template>

<style lang="stylus">
  .snackbar-container
    @apply mb-4

    .with-footer &
      margin-bottom 72px

  .snackbar .actions .text-button
    @apply text-sm

  .zoom-enter-from,
  .zoom-leave-to
    transform scale(.95)
    opacity 0

  .zoom-enter-to,
  .zoom-leave-from
    transform scale(1)
    opacity 1

  .zoom-enter-active, .zoom-leave-active
    transition .2s ease-out
    transition-property transform, opacity
</style>
