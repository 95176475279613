import * as Sentry from '@sentry/vue'
import { useConfig } from '@/config'
import { Vue } from '@sentry/vue/types/types'
import { State } from '@/store'

export async function setupSentry (app: Vue) {
  if (!import.meta.env.PROD) {
    return
  }
  const config = await useConfig()
  Sentry.init({
    app: app,
    dsn: 'https://61c046b3365542cfa44bedd6cab1d6a2@o61707.ingest.sentry.io/5378056',
    environment: import.meta.env.MODE,
    release: config.version,
    tracesSampleRate: 0,
    ignoreErrors: [
      /validator/,
      /Network Error/,
      /auth check/,
      /timeout of .* exceeded/,
    ]
  })
}

export async function setSentryContext (license: Record<string, any>, state: State) {
  Sentry.setUser({ id: license.id.toString(), username: license.name })
  Sentry.setContext('license', license)
  Sentry.setTag('CareSuite.Platform', 'AppGateway.Frontend')

  const config = await useConfig()

  Sentry.setContext('CareSuite Backend', { backend: config.backend })
  Sentry.setContext('CareSuite User State', state.user)
  Sentry.setContext('CareSuite Client State', state.client)
}
