<script setup lang="ts">
  import { useStore } from '@/store'
  import { onMounted, ref } from 'vue'
  import { Capacitor } from '@capacitor/core'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const store = useStore()
  const prompted = ref(false)

  onMounted(async () => {
    await promptMissingPermissions()
  })

  async function promptMissingPermissions () {
    if (prompted.value || !Capacitor.isNativePlatform()) {
      return
    }

    prompted.value = true

    // Ask for READ_PHONE_STATE permission if the team view is enabled.
    if (store.state.app.features.team_view) {
      await store.dispatch('app/askForPermission', {
        permission: 'android.permission.READ_PHONE_STATE',
        message: i18n.t('common.permissions.read_phone_state'),
        action: i18n.t('common.actions.allow')
      })
    }

    await store.dispatch('app/askForPermission', {
      permission: 'android.permission.USE_FULL_SCREEN_INTENT',
      message: i18n.t('common.permissions.use_full_screen_intent'),
      action: i18n.t('common.actions.allow'),
      min_sdk_version: 34,
    })
  }
</script>

<script lang="ts">
  export default {
    render () {
      return []
    }
  }
</script>

