import { loginClient, logoutUser as logoutUserRequest } from '@/api/requests'
import { WebSocket } from '@/capacitor'
import { State } from '@/store'
import { Store } from 'vuex'
import { CallListViewMode } from '@/store/ui.state'
import { Router } from 'vue-router'
import { Composer } from 'vue-i18n'

// MutedUntilCharging is the muted until date that is set when a device is charging.
export const MutedUntilCharging = "2038-01-01T00:00:00Z"

export async function loginUser (store: Store<State>, i18n: Composer, router: Router, username: string, password: string, groupId: number|null = null) {
  const response = await loginClient({
    username,
    password,
    client_id: store.state.client.client.id,
    group_id: groupId
  })

  const message = response.user.name
    ? i18n.t('login.states.logged_in_as', { name: response.user.name })
    : i18n.t('login.states.logged_in')

  const clearSnackbar = await store.dispatch('ui/showSnackbar', { message })
  window.setTimeout(clearSnackbar, 5000)

  store.commit('app/setFeatures', response.features)
  await store.dispatch('user/setUserAndGroup', {
    user: response.user,
    group: response.group
  })
  await store.dispatch('client/setClient', {
    ...response.client,
    session_id: response.session_id,
    app_user_group_id: response.group.id
  })

  await WebSocket.startService()

  store.commit('ui/promptPermissions')

  // Enforce subscriptions check after login.
  if (store.state.app.features.subscriptions_confirmation_required && !store.state.app.features.display_names) {
    await router.push({ name: 'subscription' })

    await store.dispatch('ui/showSnackbar', {
      message: i18n.t('subscription.snackbars.subscription_required'),
      timeout: 5000,
    })
  } else {
    await router.push({ name: 'callList' })
  }
}

export async function logoutUser (store: Store<State>) {
  await logoutUserRequest()

  await store.dispatch('client/logoutClient')
  await store.dispatch('user/clearUser')
  store.commit('ui/setCallListViewMode', CallListViewMode.Priority)

  await WebSocket.stopService()
}