<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import { useDocumentVisibility, useLocalStorage } from '@vueuse/core'
  import BottomNavigationButton from '@/components/material/BottomNavigationButton.vue'
  import BottomNavigation from '@/components/material/BottomNavigation.vue'
  import { mdiAccountGroup, mdiEyeOff, mdiHistory, mdiSegment, mdiStar, mdiStarOutline } from '@mdi/js'
  import { useStore } from '@/store'
  import { useCallActions, useGroupedCalls } from '@/common/call.api'
  import SectionHeading from '@/components/layout/SectionHeading.vue'
  import CallList from '@/components/calls/CallList.vue'
  import { CallListViewMode } from '@/store/ui.state'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { onBeforeRouteUpdate, useRouter } from 'vue-router'
  import ConnectionError from '@/components/layout/ConnectionError.vue'
  import { reconnect as reconnectWebsocket, subscribe as subscribeWebsocketEvents } from '@/bootstrap/websocket'
  import { logError } from '@/common/utils'
  import TeamOverview from '@/components/user/TeamOverview.vue'
  import AlertList from '@/components/alerts/AlertList.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import HistoryList from '@/components/history/HistoryList.vue'
  import { CallHandlingMode } from '@/types'
  import { useI18n } from 'vue-i18n'
  import MutedUntilText from '@/components/user/MutedUntilText.vue'

  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()

  const requestPending = ref(true)
  const ready = ref(false)
  const error = ref<false | Record<string, unknown>>(false)
  const submitting = ref(false)
  const websocket = computed(() => store.state.ui.websocketConnected)
  const mutedUntil = computed(() => store.getters['user/mutedUntil'])

  const loading = computed(() => store.state.ui.processingCallQueue || requestPending.value)

  const { release, reject, accept, mute } = useCallActions(store, i18n)

  const view = computed(() => store.state.ui.callListViewMode)
  const websocketConnected = computed(() => ready.value ? websocket.value : true)
  const visibility = useDocumentVisibility()

  const { calls } = useGroupedCalls(store)
  const alerts = computed(() => store.getters['alert/sortedRelevantAlerts'])
  const confirmedAlerts = computed(() => store.getters['alert/sortedConfirmedAlerts'])

  const showPresences = useLocalStorage<boolean>(`cs.app.calllist.presences.visible.${store.state.user.user.id}`, true)
  const isRejectOnlyCallHandlingMode = computed(() => store.state.user.group.call_handling_mode === CallHandlingMode.RejectOnly)

  const counter = computed(() => {
    let count = 0
    if (calls.value['new'].length) {
      count += calls.value['new'].length
    } else if (calls.value['own'].length) {
      count += calls.value['own'].length
    }

    if (alerts.value.length) {
      count += alerts.value.length
    }

    return count
  })

  const counterIsUrgent = computed(() => calls.value['new'].length > 0 || alerts.value.length > 0)

  function setView (view: CallListViewMode) {
    store.commit('ui/setCallListViewMode', view)
  }

  onBeforeRouteUpdate(async () => {
    await init()
  })

  onMounted(async function () {
    if (store.state.user.user.id > 0) {
      await init()
    }
  })

  // Reload data if the view becomes visible after being in the background.
  watch(() => visibility.value, async newValue => {
    if (newValue === 'visible') {
      requestPending.value = true
      await init()
    }
  })

  async function init () {
    error.value = false
    try {
      await store.dispatch('call/fetch')
    } catch (e: any) {
      logError('failed to fetch calls', e)
      error.value = e
    } finally {
      requestPending.value = false
    }
    // Give the Websocket and Service Worker some time to start before the connection is checked.
    setTimeout(() => ready.value = true, 2000)
  }

  // reconnect closes all connections and tries to reconnect to the server.
  async function reconnect () {
    requestPending.value = true
    error.value = false
    await reconnectWebsocket()
    await init()
    await subscribeWebsocketEvents(store, router)
  }

  // Re-enable notifications.
  async function unmute () {
    await store?.dispatch('user/muteNotifications', 0)

    const closeSnackbar = await store.dispatch('ui/showSnackbar', {
      message: 'Du wirst wieder benachrichtigt'
    })

    setTimeout(closeSnackbar, 3000)
  }
  // mergedErrors merges any HTTP error with the websocket connection error.
  const mergedError = computed(() => {
    const e = { ...error.value }

    const wsError = store.state.ui.websocketConnectionError
    if (wsError) {
      e.wsError = wsError
    }

    return e
  })
</script>

<template>
  <keep-alive>
    <div class="flex flex-col flex-1 h-screen-header">
      <div class="py-6 flex flex-col flex-1 overflow-x-hidden overflow-y-auto overscroll-y-auto">
        <div v-if="loading || submitting" class="flex-1 flex items-center justify-center">
          <CircularProgress />
        </div>
        <ConnectionError v-else-if="error || !websocketConnected" :error="mergedError" @retry="reconnect" />
        <div v-else class="flex flex-col flex-1 justify-start pb-2">
          <div
            v-if="mutedUntil !== null"
            class="bg-yellow-100 text-yellow-800 text-xs text-center p-4 -mt-6 mb-6"
            @click="unmute"
          >
            <MutedUntilText />
          </div>
          <!-- Priority -->
          <template v-if="view === 'priority'">
            <section v-if="alerts.length > 0" class="mb-8">
              <SectionHeading>{{ $t('common.alerts') }}</SectionHeading>
              <AlertList :alerts="alerts" />
            </section>

            <!-- Own Calls -->
            <section v-if="!isRejectOnlyCallHandlingMode" class="mb-8">
              <SectionHeading>{{ $t('calllist.own.title') }}</SectionHeading>
              <CallList
                id="call-list-own"
                :calls="calls['own']"
                @release="release"
                @reject="reject"
                @accept="accept"
                @mute="mute"
              />
            </section>

            <!-- New Calls -->
            <section v-if="calls['new'].length > 0 || isRejectOnlyCallHandlingMode" class="mb-8">
              <SectionHeading>{{ $t('calllist.new.title') }}</SectionHeading>
              <CallList
                id="call-list-new"
                :calls="calls['new']"
                @release="release"
                @reject="reject"
                @accept="accept"
                @mute="mute"
              />
            </section>

            <!-- Muted Calls (only available in reject only call handling mode) -->
            <section v-if="calls['muted'].length > 0 && isRejectOnlyCallHandlingMode" class="mb-8">
              <SectionHeading>{{ $t('calllist.muted.title') }}</SectionHeading>
              <CallList
                id="call-list-muted"
                :calls="calls['muted']"
                @mute="mute"
              />
            </section>

            <section v-if="store.state.app.features.presences">
              <div class="flex items-center justify-center relative mt-2">
                <div
                  class="absolute z-0 w-full border-b border-gray-300 top-1/2 -translate-y-1/2 transition-transform duration-300 ease-out"
                  :class="{
                    'scale-x-100': showPresences,
                    'scale-x-0': !showPresences,
                  }"
                />
                <div
                  v-wave
                  class="relative z-10 bg-gray-50 rounded-full border border-gray-300 px-2 uppercase text-xs font-semibold text-center cursor-pointer"
                  :class="{
                    'text-csBlue-600': showPresences,
                    'text-gray-400': !showPresences,
                  }"
                  @click="showPresences = !showPresences"
                >
                  <div class="flex items-center h-7">
                    <div class="flex-1 px-2">
                      <template v-if="showPresences">
                        {{ $t('common.presences') }}
                      </template>
                      <template v-else>
                        {{ $t('calllist.presences.title', {count: calls['presences'].length}) }}
                      </template>
                    </div>
                    <div class="w-4 transition-all" :class="{'!w-0 opacity-0': showPresences}">
                      <MdiIcon
                        v-if="!showPresences"
                        :icon="mdiEyeOff"
                        class="w-3.5"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <transition name="zoom">
                <CallList
                  v-if="showPresences"
                  id="call-list-presences"
                  :calls="calls['presences']"
                  :readOnly="true"
                  class="mt-4"
                  :emptyText="$t('calllist.presences.empty')"
                />
              </transition>
            </section>
          </template>

          <!-- All -->
          <template v-else-if="view === 'all'">
            <section>
              <SectionHeading class="mb-4">
                {{ $t('calllist.others.title') }}
                <div class="text-gray-500 normal-case text-2xs font-normal">
                  {{ $t('calllist.others.text') }}
                </div>
              </SectionHeading>
              <CallList
                id="call-list-others"
                :calls="calls['others']"
                @release="release"
                @reject="reject"
                @accept="accept"
                @mute="mute"
              />
            </section>
          </template>

          <!-- Team -->
          <TeamOverview v-else-if="view === 'team'" />

          <!-- History -->
          <HistoryList
            v-else
            :alerts="confirmedAlerts"
            :calls="calls['closed']"
          />
        </div>
      </div>
      <div class="flex-shrink-0">
        <BottomNavigation>
          <BottomNavigationButton
            id="view-priority"
            :active="view === 'priority'"
            :icon="mdiStarOutline"
            :iconActive="mdiStar"
            :counter="counter"
            :counterClasses="counterIsUrgent ? undefined : [view === 'priority' ? 'text-csBlue-700 bg-white border border-csBlue-100' : 'bg-gray-100 text-csBlue-700']"
            @click="setView(CallListViewMode.Priority)"
          >
            <template v-if="store.state.app.features.subscriptions">
              {{ $t('common.priorities') }}
            </template>
            <template v-else>
              {{ $t('common.calls') }}
            </template>
          </BottomNavigationButton>
          <BottomNavigationButton
            v-if="store.state.app.features.subscriptions"
            id="view-all"
            :active="view === 'all'"
            :counter="calls['others'].length"
            :counterClasses="'text-csBlue-700 bg-white border border-csBlue-100'"
            :icon="mdiSegment"
            @click="setView(CallListViewMode.All)"
          >
            {{ $t('common.others') }}
          </BottomNavigationButton>
          <BottomNavigationButton
            v-if="store.state.app.features.team_view"
            id="view-team"
            :active="view === 'team'"
            :icon="mdiAccountGroup"
            @click="setView(CallListViewMode.Team)"
          >
            {{ $t('common.people') }}
          </BottomNavigationButton>
          <BottomNavigationButton
            id="view-history"
            :active="view === 'history'"
            :icon="mdiHistory"
            @click="setView(CallListViewMode.History)"
          >
            {{ $t('common.history') }}
          </BottomNavigationButton>
        </BottomNavigation>
      </div>
    </div>
  </keep-alive>
</template>

