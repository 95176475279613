import { Preferences } from '@capacitor/preferences'
import { ifNative, State } from '@/capacitor'

// storagePartition is used to partition data between multiple instances of the app.
// This feature is primarily used in the seller's /demo route where the app is loaded in
// separate iframes that need to act as independent instances and cannot share state.
let storagePartition = ''

// Use the ?storagePartition parameter as prefix for all storage keys.
if (window.CS_DEMO?.storagePartition) {
  storagePartition = window.CS_DEMO.storagePartition
}

// storageKey returns a partitioned storage key.
const storageKey = (key: string) => `${storagePartition}${key}`

// storageSet sets a value in the storage.
export async function storageSet (key: string, value: any): Promise<void> {
  await Preferences.set({
    key: storageKey(key),
    value: value,
  })

  ifNative(async () => {
    await State.refresh()
  })
}

// storageGet returns a value from the storage.
export async function storageGet (key: string): Promise<any> {
  key = storageKey(key)
  // Cypress has problems with waiting for the Preferences.get promise to resolve,
  // so we short circuit the access to localStorage here when E2E tests are running.
  if (window.hasOwnProperty('Cypress')) {
    return localStorage.getItem(`CapacitorStorage.${key}`)
  }

  const item = await Preferences.get({ key: key })
  if (!item.value) {
    return null
  }
  return item.value
}

// storageRemove removes a value from storage.
export async function storageRemove (key: string): Promise<void> {
  await Preferences.remove({
    key: storageKey(key),
  })
}
