<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
  import { ifNative, State as StatePlugin, WebSocket, WifiManager } from '@/capacitor'
  import { useStore } from '@/store'
  import { CallListViewMode } from '@/store/ui.state'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import { useRouter } from 'vue-router'
  import { useConfig } from '@/config'
  import { wifiInfo } from '@/capacitor/WifiManagerPlugin'
  import { mdiChevronRight } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'

  const store = useStore()
  const router = useRouter()

  const config = ref({})
  const backendState = ref<{ userState: string, clientState: string, backend: string }>({
    userState: '',
    clientState: '',
    backend: '',
  })
  const wifiState = ref<wifiInfo>({
    ip: 'unknown',
    percentage: 100,
  })

  onMounted(async () => {
    config.value = await useConfig()

    ifNative(async () => {
      const state = await StatePlugin.fromBackend()

      backendState.value.backend = state.backend

      try {
        if (state.userState) {
          backendState.value.userState = JSON.parse(state.userState)
        } else {
          backendState.value.userState = state.userState
        }
      } catch (e) {
        backendState.value.userState = state.userState
      }

      try {
        if (state.clientState) {
          backendState.value.clientState = JSON.parse(state.clientState)
        } else {
          backendState.value.clientState = state.clientState
        }
      } catch (e) {
        backendState.value.clientState = state.clientState
      }
    })
  })

  const vuex = computed(() => ({ ...store.state }))

  const showConfig = ref(false)

  let pollingInterval: number | undefined
  onMounted(() => {
    clearInterval(pollingInterval)

    ifNative(() => {
      pollingInterval = setInterval(async () => {
        wifiState.value = await WifiManager.getWifiInfo()
      }, 500)
    })
  })

  onBeforeUnmount(() => {
    clearInterval(pollingInterval)
  })

  function close () {
    store.commit('ui/setDebug', false)
  }

  async function logout () {
    if (!confirm('Nach dem Abmelden ist ein erneutes Login mit Benutzername und Passwort notwenig. Fortfahren?')) {
      return
    }

    await router.push({ name: 'logout' })
    close()
  }

  async function unregister () {
    if (!confirm('Das Gerät muss anschliessend neu mit dem CareSuite Server verbunden werden. Fortfahren?')) {
      return
    }

    await store.dispatch('client/logoutClient')
    store.commit('ui/setCallListViewMode', CallListViewMode.Priority)
    await store.dispatch('user/clearUser')
    await store.dispatch('client/clearClient')

    await WebSocket.stopService()
    await router.push({ name: 'register' })
    close()
  }
</script>

<template>
  <div class="fixed flex flex-col inset-0 bg-black/90 text-white z-50 p-4 backdrop-blur">
    <div class="absolute z-10 px-4 py-1 cursor-pointer right-0 top-0 text-4xl" @click="close">
      &times;
    </div>
    <div class="container mx-auto">
      <div class="p-4 mt-14 bg-black/50 rounded">
        <h2 class="font-semibold mb-4 flex items-center">
          {{ $t('debug.network_info') }}
        </h2>
        <dl class="leading-none text-sm space-y-3">
          <div class="flex justify-between">
            <dt>
              IP
            </dt>
            <dd>
              {{ wifiState.ip }}
            </dd>
          </div>
          <div class="flex justify-between">
            <dt>
              {{ $t('debug.signal_strength') }}
            </dt>
            <dd class="flex-1 flex ml-10 relative items-center">
              <div class="h-3 relative w-full flex-1 flex items-center text-2xs">
                <div class="h-2 w-full rounded-full bg-white/15 absolute shadow-inner">
                  <div
                    class="absolute inset-0 rounded-full transition-all duration-100"
                    :class="{ 'bg-red-600': wifiState.percentage < 40, 'bg-yellow-600': wifiState.percentage >= 40 && wifiState.percentage < 80, 'bg-green-600': wifiState.percentage >= 80}"
                    :style="{'width': `${wifiState.percentage}%`}"
                  />
                </div>
              </div>
              <div class="w-16 h-full flex justify-end items-center">
                {{ wifiState.percentage }}%
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div class="p-4 mt-4 overflow-y-auto bg-black/50 rounded">
        <h2 class="select-none cursor-pointer font-semibold flex items-center" @click="showConfig = !showConfig">
          <div class="flex-0 mr-4">
            <MdiIcon
              :icon="mdiChevronRight"
              class="w-5 transform origin-center"
              :class="{'!rotate-90': showConfig}"
            />
          </div>
          <div class="flex-1">
            {{ $t('debug.config') }}
          </div>
        </h2>
        <div
          class="space-y-4 mt-2"
          :class="{'max-h-0 overflow-hidden !mt-0': !showConfig}"
        >
          <div class="text-wrap text-3xs font-mono">
            <p class="font-bold">
              JS
            </p>
            <pre>{{ config }}</pre>
          </div>
          <div class="text-wrap text-3xs font-mono">
            <p class="font-bold">
              Java
            </p>
            <pre>{{ backendState }}</pre>
          </div>
          <div class="text-wrap text-3xs font-mono">
            <p class="font-bold">
              Vuex
            </p>
            <pre class="max-h-[100vh] overflow-auto">{{ vuex }}</pre>
          </div>
        </div>
      </div>
      <div class="p-4 mt-6 space-y-4 mx-auto max-w-2xl">
        <ContainedButton class="bg-green-600 w-full" @click.prevent="logout">
          {{ $t('common.actions.logout') }}
        </ContainedButton>
        <ContainedButton class="bg-red-600 w-full" @click.prevent="unregister">
          {{ $t('debug.actions.delete_registration') }}
        </ContainedButton>
      </div>
    </div>
  </div>
</template>