import { State } from '@/store/index'
import { Action } from 'vuex'
import { Features } from '@/types'
import { Permissions as PermissionsPlugin } from '@/capacitor'
import { logDebug, logInfo } from '@/common/utils'

export interface AppState {
  features: Features
  permissions: Permissions
}

interface Permissions extends Record<string, boolean> {
  'android.permission.READ_PHONE_STATE': boolean
}

const state: AppState = {
  features: {
    presences: true,
    display_names: false,
    team_view: false,
    subscriptions: false,
    subscriptions_user_defined: false,
    subscriptions_confirmation_required: false,
    logout_disabled: false,
    version: 0,
  },
  permissions: {
    'android.permission.READ_PHONE_STATE': false,
  },
}

const getters = {}

const mutations = {
  setFeatures (state: AppState, features: Features | undefined) {
    if (features !== undefined && typeof features === 'object') {
      state.features = features
    }
  },
  setPermissions (state: AppState, permissions: Permissions) {
    state.permissions = permissions
  }
}

const actions: Record<string, Action<AppState, State>> = {
  /**
   * Ask for a native Android permission.
   * Calls to this action only trigger once per borwser session.
   */
  async askForPermission ({ dispatch, state, commit }, payload: { permission: string, message: string, min_sdk_version: number, action: string }) {
    if (state.permissions.hasOwnProperty(payload.permission) && state.permissions[payload.permission]) {
      logDebug('permission already granted (cached)', payload.permission)
      return
    }

    const { granted } = await PermissionsPlugin.granted({ permission: payload.permission, min_sdk_version: payload.min_sdk_version })
    if (granted) {
      logDebug('permission is already granted', payload.permission)
      commit('setPermissions', { ...state.permissions, [payload.permission]: true })
      return
    }

    // Make sure to only prompt for this permission once every browser session.
    const cacheKey = `permission_asked.${payload.permission}`
    if (sessionStorage.getItem(cacheKey)) {
      logInfo('skip recurring ask for permission', payload.permission)
      return
    }

    sessionStorage.setItem(cacheKey, 'true')

    await dispatch('ui/showSnackbar', {
      message: payload.message,
      action: payload.action,
      actionFn: async () => {
        const { granted } = await PermissionsPlugin.prompt({ permission: payload.permission })
        if (granted) {
          logInfo('permission granted', payload.permission)
          commit('setPermissions', { ...state.permissions, [payload.permission]: true })
        }
      }
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
