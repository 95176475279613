<script setup lang="ts">
  import { registerClient } from '@/api/requests'
  import { setSentryContext } from '@/bootstrap/sentry'
  import { logDebug, logError } from '@/common/utils'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import IllustrationRegistration from '@/components/illustration/IllustrationRegistration.vue'
  import { useToken } from '@/bootstrap/firebase'
  import { computed, onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { setBackend, useConfig } from '@/config'
  import { useStore } from '@/store'
  import { reconnect } from '@/bootstrap/websocket'
  import { setupAxios } from '@/bootstrap/http'
  import { mdiQrcodeScan } from '@mdi/js'

  import ErrorDetails from '@/components/debug/ErrorDetails.vue'

  import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import FormInput from '@/components/material/FormInput.vue'
  import { ifNativeElse, State } from '@/capacitor'
  import QRCodeScanner from '@/components/qrcode/QRCodeScanner.vue'
  import { useI18n } from 'vue-i18n'
  import { Locale } from '@/types'

  defineProps({
    state: {
      type: String,
      default: '',
    }
  })

  const i18n = useI18n()

  const errorDetails = ref({})
  const errorDetailsVisible = ref(false)
  const manualRegistrationVisible = ref(false)

  const router = useRouter()
  const store = useStore()
  const pairingToken = ref('')
  const backend = ref('')
  const token = useToken()
  const deviceName = ref('')
  const phoneNumber = ref('')
  const error = ref({ backend: '', code: '' })
  const loading = ref(false)

  const httpBackend = computed(() => {
    if (backend.value.startsWith('http')) {
      return backend.value
    }
    return `http://${backend.value}`
  })

  onMounted(async () => {
    const config = await useConfig()
    if (config.backend) {
      backend.value = config.backend
    }

    if (config.isDemoClient) {
      pairingToken.value = '0000'
      phoneNumber.value = (Date.now() % 10000).toString()
      if (config.backend) {
        backend.value = config.backend
      }

      await register()
    }

    // Fallback to manual registration if barcode scanner is not supported.
    ifNativeElse(async () => {
      try {
        const { supported } = await BarcodeScanner.isSupported()
        manualRegistrationVisible.value = !supported
      } catch (e) {
        manualRegistrationVisible.value = true
        logError('Barcode scanner not supported:', e)
      }
    }, () => {
      manualRegistrationVisible.value = true
    })

    const { name } = await State.getDeviceName()
    deviceName.value = name
  })

  async function register () {
    const config = await useConfig()

    error.value = { backend: '', code: '' }
    errorDetails.value = {}

    loading.value = true

    try {
      // set up a new axios instance with the given backend url.
      setupAxios(store, router, i18n.locale.value as Locale, httpBackend.value, config.version)

      const response = await registerClient({
        pairing_token: pairingToken.value.toString(),
        messaging_token: token.value || '',
        name: deviceName.value,
        phone_number: phoneNumber.value,
      })

      logDebug('Received client state is', response as any)
      await store.dispatch('client/setClient', response.client)
      store.commit('ui/setTimeouts', response.timeouts)

      await setBackend(httpBackend.value)
      await setSentryContext(response.license, store.state)
      await reconnect()

      const close = await store.dispatch('ui/showSnackbar', {
        message: i18n.t('registration.snackbars.success'),
      })
      setTimeout(close, 5000)

      await router.push({ name: 'login' })
    } catch (e: any) {
      errorDetails.value = e
      if (e?.code === 'ERR_NETWORK') {
        error.value.backend = i18n.t('registration.errors.network', [httpBackend.value])
        error.value.code = ''
      } else if (e?.code === 'ERR_BAD_REQUEST') {
        console.log(e.response.data)
        error.value.backend = i18n.t('common.states.known_error', [e?.response?.data?.error ?? i18n.t('common.states.unknown_error')])
        error.value.code = ''
      } else {
        pairingToken.value = ''
        error.value.backend = ''
        error.value.code = i18n.t('registration.errors.invalid_token')
      }
      logError('Error registering:', e)
    } finally {
      loading.value = false
    }
  }

  const qrCodeScanningRunning = ref(false)

  function scanQRCode () {
    qrCodeScanningRunning.value = true
    backend.value = ''
    pairingToken.value = ''
  }

  async function onQRCodeResult (result: string) {
    try {
      const json = JSON.parse(result)

      if (!json.server_url || !json.registration_token) {
        error.value.backend = i18n.t('common.invalid_qr_code')
        error.value.code = ''
        return
      }

      backend.value = json.server_url ?? ''
      pairingToken.value = json.registration_token ?? ''
    } catch (e) {
      error.value.backend = i18n.t('common.invalid_qr_code')
      error.value.code = ''
      return
    } finally {
      qrCodeScanningRunning.value = false
    }

    await register()
  }
</script>

<template>
  <div class="bg-white h-screen-header pt-4 overflow-y-auto">
    <div
      v-if="state === 'invalid'"
      class="-mt-4 bg-yellow-100 text-yellow-800 p-4 rounded text-sm leading-tight text-center"
    >
      {{ $t('registration.invalid_registration') }}
    </div>
    <div class="bg-white p-6">
      <!-- Loading overlay -->
      <div v-if="loading" class="fixed inset-0 bg-white/50 backdrop-blur-sm z-10 grid place-items-center">
        <div class="space-y-4 text-center">
          <CircularProgress />
          <div class="mt-2 text-xs text-gray-800">
            {{ $t('registration.registering_device') }}
          </div>
        </div>
      </div>

      <IllustrationRegistration />

      <div class="max-w-2xl mx-auto">
        <h1 class="text-3xl mt-8 mb-6 text-csBlue-800 font-semibold leading-none">
          {{ $t('registration.title') }}
        </h1>

        <div class="text-base space-y-2 text-gray-800 leading-tight">
          <p>
            {{ $t('registration.intro') }}
          </p>
        </div>

        <div
          v-if="error.backend || error.code"
          class="bg-red-100 border-red-500 text-red-800 p-2 mt-4 rounded text-xs text-center"
          @click.prevent="errorDetailsVisible = true"
        >
          <div v-if="error.backend">
            {{ error.backend }}
          </div>
          <div v-if="error.code">
            {{ error.code }}
          </div>
          <div v-if="!errorDetailsVisible">
            <span class="underline cursor-pointer">
              {{ $t('common.actions.show_details') }}
            </span>
          </div>
        </div>

        <ErrorDetails
          class="mt-4"
          :visible="errorDetailsVisible"
          :errorDetails="errorDetails"
          @hide="errorDetailsVisible = false"
        />

        <div class="my-6 space-y-4">
          <FormInput
            id="name"
            v-model="deviceName"
            :label="$t('registration.fields.device_name')"
          />
          <FormInput
            id="phone"
            v-model="phoneNumber"
            :label="$t('registration.fields.phone_number')"
          />
          <template v-if="manualRegistrationVisible">
            <FormInput
              id="backend"
              v-model="backend"
              :label="$t('registration.fields.server_url')"
            />
            <FormInput
              id="token"
              v-model="pairingToken"
              :label="$t('registration.fields.registration_code')"
            />
          </template>
          <ContainedButton
            v-if="!manualRegistrationVisible"
            id="submit"
            class="bg-csBlue-500 w-full"
            @click="scanQRCode"
          >
            <div class="flex items-center">
              <MdiIcon :icon="mdiQrcodeScan" class="mr-4" />
              <span>
                {{ $t('common.actions.scan_qr_code') }}
              </span>
            </div>
          </ContainedButton>
          <ContainedButton
            v-else
            id="submit"
            class="bg-csBlue-500 w-full"
            @click="register"
          >
            {{ $t('registration.register_device') }}
          </ContainedButton>
          <button
            v-if="!manualRegistrationVisible"
            class="text-xs text-csBlue-600 underline-offset-3 underline text-center w-full"
            @click="manualRegistrationVisible = true"
          >
            {{ $t('registration.manual_registration') }}
          </button>
        </div>
      </div>
    </div>
    <QRCodeScanner :scan="qrCodeScanningRunning" @result="onQRCodeResult" @close="qrCodeScanningRunning = false" />
  </div>
</template>