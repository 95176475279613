import { differenceInSeconds, isAfter, parseISO, useStaticTime, useTime } from '@/common/time'
import { logError, logTrace } from '@/common/utils'
import { State } from '@/store'
import { Call, CallAction, CallHandlingMode, CallUrgency, User } from '@/types'
import { computed, Ref } from 'vue'
import { Store } from 'vuex'
import { CallSection, CallState, groupCallsForUser } from '@/store/call.state'
import { Composer } from "vue-i18n"

export enum CallActionEvent {
  Accept = 'accept',
  Reject = 'reject',
  Release = 'release',
  Mute = 'mute',
}

export function useCallStates (store: Store<State>, call: Ref<Call | undefined>) {
  const time = useTime()

  // escalationSeconds is the duration until a new and unhandled call is escalated.
  const escalationSeconds = computed(() => store.state.ui.timeouts.escalation)

  const callAgeInSeconds = computed(() => {
    if (!call.value) {
      return 0
    }
    // If the call was closed, calculate the duration to the closed date, otherwise use now.
    const relativeTo = call.value?.closed_at ? parseISO(call.value?.closed_at) : time.value.now
    return differenceInSeconds(relativeTo, parseISO(call.value.opened_at))
  })
  const isPresence = computed(() => !!call.value?.is_presence)
  const secondsUntilRelease = computed(() => call.value?.releases_at ? Math.max(differenceInSeconds(parseISO(call.value.releases_at), time.value.now), 0) : null)
  const secondsUntilEscalation = computed(() => {
    const useValue = call.value?.escalated_at ?? call.value?.escalates_at
    return useValue ? differenceInSeconds(parseISO(useValue), time.value.now) : Infinity
  })
  // Value between 0 and 1 of the escalation time (- escalationSeconds)
  const escalationPercentage = computed(() => {
    if (isPresence.value || isRejectOnlyCallHandlingMode.value) {
      return 0
    }
    return Math.min(1, 1 - (1 / escalationSeconds.value * secondsUntilEscalation.value))
  })

  const isRejectOnlyCallHandlingMode = computed(() => store.state.user.group.call_handling_mode === CallHandlingMode.RejectOnly)

  const markEscalated = computed(() => !isRejectOnlyCallHandlingMode.value && !isClosed.value && !isAccepted.value && escalationPercentage.value === 1 && !isMutedByMyself.value)

  // Accepted by any client, rejected or released.
  const isHandled = computed(() => isPresence.value || isAccepted.value || isMutedByMyself.value || (isRejectedByMyself.value && escalationPercentage.value === 0) || (isReleasedByMyself.value && escalationPercentage.value === 0) || isClosed.value)
  // Rejected by myself.
  const isRejectedByMyself = computed(() => call.value ? !isAccepted.value && store.state.call.rejectedCalls.hasOwnProperty(call.value.id) : false)
  // If CallHandlingMode.RejectOnly is active and the call has been rejected, it is considered as muted.
  const isMutedByMyself = computed(() => isRejectOnlyCallHandlingMode.value && isRejectedByMyself.value)
  // Released by myself.
  const isReleasedByMyself = computed(() => call.value ? !isAccepted.value && store.state.call.releasedCalls.hasOwnProperty(call.value.id) : false)
  // Accepted by any client.
  const isAccepted = computed(() => isPresence.value || call.value?.accepted_by_user_id !== null)
  // Accepted by myself.
  const isAcceptedByMyself = computed(() => call.value?.accepted_by_user_id === store.state.user.user.id)
  // Call is closed.
  const isClosed = computed(() => !!call.value?.closed_at)
  // The Call has not been muted since it was last resent.
  const isRejectedSinceLastResend = computed(() => {
    if (!call.value) {
      return false
    }
    if (!store.state.call.rejectedCalls.hasOwnProperty(call.value.id)) {
      return false
    }
    if (!call.value.resent_at) {
      return false
    }

    const rejectionTime = store.state.call.rejectedCalls[call.value.id]
    const rejectedAt = parseISO(rejectionTime)
    const resentAt = parseISO(call.value.resent_at)

    return isAfter(rejectedAt, resentAt)
  })

  const isUrgent = computed(() => call.value ? hasElevatedUrgency(call.value) : false)

  return {
    isClosed,
    isHandled,
    isRejectedByMyself,
    isReleasedByMyself,
    isMutedByMyself,
    isAccepted,
    isPresence,
    isUrgent,
    isAcceptedByMyself,
    isRejectOnlyCallHandlingMode,
    isRejectedSinceLastResend,
    callAgeInSeconds,
    secondsUntilEscalation,
    secondsUntilRelease,
    escalationSeconds,
    escalationPercentage,
    markEscalated,
  }
}

// useCallActions returns the available actions that can be executed for a call.
export function useCallActions (store: Store<State>, i18n: Composer) {
  const undo = computed(() => store.state.call.undo)

  async function release (id: number) {
    const closeSnackbar = await store.dispatch('ui/showSnackbar', {
      message: i18n.t('calldetail.snackbars.release'),
      action: i18n.t('common.actions.undo'),
      actionFn: undo,
    })

    try {
      const response = await store.dispatch('call/release', {
        id,
        finally: () => {
          closeSnackbar()
        }
      })
      logTrace('response is', response)
    } catch (e) {
      logError('failed to release call', e)
    }
  }

  async function reject (id: number) {
    const closeSnackbar = await store.dispatch('ui/showSnackbar', {
      message: i18n.t('calldetail.snackbars.reject'),
      action: i18n.t('common.actions.undo'),
      actionFn: undo,
    })

    try {
      const response = await store.dispatch('call/reject', {
        id,
        finally: () => {
          closeSnackbar()
        }
      })
      logTrace('response is', response)
    } catch (e) {
      logError('failed to reject call', e)
    }
  }

  async function accept (id: number) {
    const closeSnackbar = await store.dispatch('ui/showSnackbar', {
      message: i18n.t('calldetail.snackbars.accept'),
      action: i18n.t('common.actions.undo'),
      actionFn: undo,
    })

    try {
      const response = await store.dispatch('call/accept', {
        id,
        username: store.state.user.user.name,
        userId: store.state.user.user.id,
        finally: () => {
          closeSnackbar()
        }
      })
      logTrace('response is', response)
    } catch (e) {
      logError('failed to accept call', e)
    }
  }

  async function mute (id: number) {
    const closeSnackbar = await store.dispatch('ui/showSnackbar', {
      message: i18n.t('calldetail.snackbars.mute'),
      action: i18n.t('common.actions.undo'),
      actionFn: undo,
    })

    try {
      const response = await store.dispatch('call/mute', {
        id,
        finally: () => {
          closeSnackbar()
        }
      })
      logTrace('response is', response)
    } catch (e) {
      logError('failed to mute call', e)
    }
  }

  const actions: Record<CallAction, string> = {
    [CallAction.Rejected]: i18n.t('calldetail.states.rejected'),
    [CallAction.Accepted]: i18n.t('calldetail.states.accepted'),
    [CallAction.Released]: i18n.t('calldetail.states.released'),
    [CallAction.Escalated]: i18n.t('calldetail.states.escalated'),
    [CallAction.Resent]: i18n.t('calldetail.states.resent'),
    [CallAction.Muted]: i18n.t('calldetail.states.muted'),
  }

  function translateAction (action: CallAction) {
    return actions.hasOwnProperty(action) ? actions[action] : action
  }

  return { accept, reject, release, mute, translateAction }

}

// hasElevatedUrgency returns true if a call urgency is Urgent or Emergency.
export function hasElevatedUrgency (call: Call) {
  return [CallUrgency.Emergency, CallUrgency.Urgent].includes(call.urgency)
}

// isEmergency returns true if a call is an emergency call.
export function isEmergency (call: Call) {
  return call.urgency.toLowerCase() === CallUrgency.Emergency.toLowerCase()
}

export type GroupedCallState = {
  [k in CallSection]: Call[]
}

// CallGrouper groups calls into CallSections based on their status.
export class CallGrouper {
  public constructor (
    private user: User,
    private mode: CallHandlingMode,
    private state: CallState,
    private usePriorities: boolean = false,
    private serverVersion: number = 0
  ) {
  }

  public group (calls: Call[]): GroupedCallState {
    const grouped: GroupedCallState = {
      own: [],
      new: [],
      others: [],
      presences: [],
      closed: [],
      muted: [],
    }

    calls.forEach(call => grouped[this.section(call)].push(call))

    return grouped
  }

  private section (call: Call): CallSection {
    // Presences, only include if not closed, otherwise push them into the closed group.
    if (call.is_presence) {
      return call.closed_at ? CallSection.Closed : CallSection.Presences
    }

    // Closed calls.
    if (call.closed_at) {
      return CallSection.Closed
    }

    // Open calls when call handling is RejectOnly.
    if (this.mode === CallHandlingMode.RejectOnly) {
      if (this.isPrimarySelection(call) || this.isEscalated(call)) {
        return this.isMuted(call) ? CallSection.Muted : CallSection.New
      } else {
        return CallSection.Others
      }
    }

    // Accepted, own calls.
    if (this.isAcceptedByMyself(call)) {
      return CallSection.Own
    }

    // All other accepted calls  go to the History section.
    if (this.isAccepted(call)) {
      return CallSection.Closed
    }

    // Pending calls that are not the primary section go under "Others".
    if (!this.isPrimarySelection(call) && !this.isEscalated(call)) {
      return CallSection.Others
    }

    // Pending calls, only if primary selection or has escalated.
    // Always show the first escalation, otherwise only if it was not recently rejected.
    if (!this.releasedSinceLastResend(call) && this.justEscalatedOrNotRejectedSinceLastResend(call) && this.isUnhandledAndEscalatedOrPrimarySelection(call) && !this.isMuted(call)) {
      return CallSection.New
    }

    // Everything else goes under "History".
    return CallSection.Closed
  }

  private justEscalatedOrNotRejectedSinceLastResend (call: Call): boolean {
    return this.justEscalated(call) || !this.rejectedSinceLastResend(call)
  }

  private justEscalated (call: Call) {
    if (!call.escalated_at) {
      return false
    }

    return differenceInSeconds(useStaticTime().now, parseISO(call.escalated_at)) < 10
  }

  private rejectedSinceLastResend (call: Call) {
    if (!this.state.rejectedCalls.hasOwnProperty(call.id)) {
      return false
    }

    if (!call.resent_at) {
      return true
    }

    const rejectedAt = parseISO(this.state.rejectedCalls[call.id])
    const resentAt = parseISO(call.resent_at)

    return isAfter(rejectedAt, resentAt)
  }

  private releasedSinceLastResend (call: Call) {
    if (!this.state.releasedCalls.hasOwnProperty(call.id)) {
      return false
    }

    if (!call.resent_at) {
      return true
    }

    const releasedAt = parseISO(this.state.releasedCalls[call.id])
    const resentAt = parseISO(call.resent_at)

    return isAfter(releasedAt, resentAt)
  }

  private isAcceptedByMyself (call: Call) {
    return call.accepted_by_user_id === this.user.id
  }

  private isAccepted (call: Call) {
    return call.accepted_by_user_id !== null
  }

  private isReleased (call: Call) {
    return this.state.releasedCalls.hasOwnProperty(call.id)
  }

  private isRejected (call: Call) {
    return this.state.rejectedCalls.hasOwnProperty(call.id)
  }

  private isPrimarySelection (call: Call) {
    const priority = this.user.nodes_priority

    if (this.serverVersion >= 356) {
      // If Priorities are disabled, everything is a priority call.
      if (!this.usePriorities) {
        return true
      }

      // If no priority selection is available, nothing is a priority call.
      if (!priority || !priority?.length) {
        return false
      }
    } else {
      if (!priority || !priority?.length) {
        return true
      }
    }

    return call.nodes.some(node => priority.includes(node))
  }

  private isEscalated (call: Call) {
    return call.escalated_at !== null
  }

  private isUnhandled (call: Call) {
    return call.accepted_by_user_id === null
  }

  private isUnhandledAndEscalated (call: Call) {
    return this.isUnhandled(call) && this.isEscalated(call)
  }

  private isUnhandledAndEscalatedOrPrimarySelection (call: Call) {
    return this.isUnhandledAndEscalated(call) || this.isPrimarySelection(call)
  }

  private isMuted (call: Call) {
    // Only RejectOnly groups can mute calls.
    if (this.mode !== CallHandlingMode.RejectOnly) {
      return false
    }

    // Muted calls are stored in the rejectedCalls state.
    return this.isRejected(call)
  }
}

// useGroupedCalls returns a computed property that contains the current user's calls grouped into display sections.
export function useGroupedCalls (store: Store<State>) {
  const user = computed(() => store.state.user.user)
  const callHandlingMode = computed(() => store.state.user.group.call_handling_mode)
  const allCalls = computed(() => store.state.call.calls)

  const calls = computed<GroupedCallState>(() => {
    if (!user.value) {
      return { 'own': [], 'new': [], 'others': [], 'presences': [], 'closed': [], 'muted': [] }
    }

    return groupCallsForUser(allCalls.value, user.value, callHandlingMode.value, store.state.app.features.subscriptions, store.state.app.features.version)
  })

  return { calls }
}