<script setup lang="ts">
  import SectionHeading from '@/components/layout/SectionHeading.vue'
  import { mdiAlert, mdiClose, mdiMagnify } from '@mdi/js'
  import { computed, onBeforeUnmount, onMounted, Ref, ref } from 'vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { getUsers } from '@/api/requests'
  import { Group, UserClientCall } from '@/types'
  import Callout from '@/components/layout/Callout.vue'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import { logError } from '@/common/utils'
  import TeamMember from '@/components/user/TeamMember.vue'
  import SegmentedButtons from '@/components/material/SegmentedButtons.vue'
  import { useLocalStorage } from '@vueuse/core'
  import { useStore } from '@/store'
  import { useI18n } from 'vue-i18n'
  import MdiIcon from '@/components/ui/MdiIcon.vue'

  const i18n = useI18n()
  const store = useStore()

  const loading = ref(true)
  const error = ref<any>('')
  const userClients = ref<UserClientCall[]>([])
  const groups = ref<Group[]>([])
  const query = ref('')

  const groupBy = useLocalStorage(`cs.app.team_overview.group_by.${store.state.user.user.id}`, 'team') as unknown as Ref<string>
  const groupByOptions = [
    { label: i18n.t('people.team'), value: 'team' },
    { label: i18n.t('people.all'), value: 'all' },
  ]

  // Group ID lookup.
  const groupsById = computed(() => {
    const result: Record<number, Group> = {}
    groups.value.forEach((group) => {
      result[group.id] = group
    })
    return result
  })

  const groupIdAndMainGroupIdMap = computed(() => {
    const result: Record<number, number> = {}
    groups.value.forEach((group) => {
      result[group.id] = group.main_group_id ?? group.id
    })
    return result
  })

  // Filtered list of all user clients.
  const filteredUserClients = computed(() => {
    if (!query.value) {
      return userClients.value
    }

    const cleanQuery = query.value.trim().toLowerCase()

    return userClients.value.filter((userClient) => {
      const userClientName = userClient.user.current_display_name || userClient.user.name
      if (userClientName.toLowerCase().includes(cleanQuery)) {
        return true
      }

      if (userClient.client.phone_number.includes(cleanQuery)) {
        return true
      }

      return false
    })
  })

  // All user clients, alphabetically sorted by the user's name.
  const sortedUserClients = computed(() => {
    return [...filteredUserClients.value].sort((a, b) => {
      const aName = a.user.current_display_name || a.user.name
      const bName = b.user.current_display_name || b.user.name
      return aName.localeCompare(bName)
    })
  })

  // Group the user clients by the selected groupBy option.
  const groupedList = computed(() => {
    const result: Record<string, UserClientCall[]> = {}

    let callback: (data: UserClientCall) => string | false = () => false
    switch (groupBy.value) {
    case 'team':
      callback = (userClient) => {
        const userClientMainGroupId = groupIdAndMainGroupIdMap.value[userClient.user.app_user_group_id] ?? -1
        const storedUserMainGroupId = groupIdAndMainGroupIdMap.value[store.state.user.user.app_user_group_id] ?? -1

        if (userClientMainGroupId === storedUserMainGroupId) {
          return groupsById.value[userClientMainGroupId]?.name ?? 'Unbekannte Gruppe'
        }
        return false
      }
      break
    case 'all':
      callback = (userClient) => groupsById.value[groupIdAndMainGroupIdMap.value[userClient.user.app_user_group_id] ?? -1]?.name ?? 'Unbekannte Gruppe'
      break
    default:
      throw new Error('Unknown groupBy value')
    }

    // Group the user clients by the callback result.
    sortedUserClients.value.forEach((userClient) => {
      const key = callback(userClient)
      if (!key) {
        return
      }
      if (!result[key]) {
        result[key] = []
      }
      result[key].push(userClient)
    })

    // Sort the groups alphabetically.
    const sortedResult: Record<string, UserClientCall[]> = {}

    Object.keys(result).sort().forEach((key) => sortedResult[key] = result[key])

    return sortedResult
  })

  const minItemsForSearch = 10
  const showSearch = computed(() => {
    if (groupBy.value === 'all') {
      return userClients.value.length > minItemsForSearch
    }

    const usersInSameTeam = userClients.value.filter((userClient) => {
      const userClientMainGroupId = groupIdAndMainGroupIdMap.value[userClient.user.app_user_group_id] ?? -1
      const storedUserMainGroupId = groupIdAndMainGroupIdMap.value[store.state.user.user.app_user_group_id] ?? -1

      return userClientMainGroupId === storedUserMainGroupId
    })

    return usersInSameTeam.length > minItemsForSearch
  })

  // Fetch the team data.
  async function fetchTeam (silent: boolean = false) {
    if (!silent) {
      loading.value = true
    }

    error.value = ''
    try {
      const response = await getUsers()
      groups.value = response.groups ?? []
      // Remove the current user from the list.
      userClients.value = response.data.filter(data => data.user.id !== store.state.user.user.id) ?? []
    } catch (e: any) {
      error.value = e
      logError(e)
    } finally {
      loading.value = false
    }
  }

  const pollTimeout = ref<null | number>(null)
  const pollCount = ref(0)

  // Increase the poll timeout the longer the user stays on the view.
  const pollDelay = computed(() => {
    switch (true) {
    case pollCount.value < 5:
      return 2000
    case pollCount.value < 10:
      return 4000
    default:
      return 6000
    }
  })

  async function poll () {
    if (!error.value) {
      pollCount.value++
      await fetchTeam(true)
    }

    if (pollTimeout.value) {
      window.clearTimeout(pollTimeout.value)
    }

    pollTimeout.value = window.setTimeout(poll, pollDelay.value)
  }

  onMounted(() => {
    fetchTeam()
    poll()
  })

  onBeforeUnmount(() => {
    pollCount.value = 0
    if (pollTimeout.value) {
      window.clearTimeout(pollTimeout.value)
    }
  })
</script>

<template>
  <div v-if="loading" class="flex flex-1 items-center justify-center">
    <CircularProgress />
  </div>

  <Callout v-else-if="error" :title="$t('common.states.error')" :icon="mdiAlert">
    <p class="mb-4">
      {{ $t('common.callouts.unable_to_load_data') }}
    </p>
    <template #actions>
      <ContainedButton class="bg-csBlue-800 !mt-8" @click="fetchTeam">
        {{ $t('common.actions.retry') }}
      </ContainedButton>
    </template>
  </Callout>

  <section v-else>
    <SectionHeading>{{ $t('people.team') }}</SectionHeading>

    <div class="flex justify-center px-4 mt-2">
      <SegmentedButtons
        v-model="groupBy"
        :options="groupByOptions"
      />
    </div>

    <div class="mt-4 max-w-xl mx-auto px-4">
      <!-- Search -->
      <transition name="search">
        <div v-if="showSearch" class="flex items-center relative pb-4">
          <div class="absolute pointer-events-none left-0 h-full flex items-center justify-center w-8 ml-2">
            <MdiIcon :icon="mdiMagnify" class="size-5 text-gray-600 mt-px" />
          </div>
          <input
            id="query"
            v-model="query"
            type="search"
            class="w-full pl-10 pr-10 px-4 py-2 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring focus:border-csBlue-700"
            autocomplete="off"
            :placeholder="$t('common.search')"
          >
          <div class="absolute right-0 w-12 h-full">
            <button
              v-if="query"
              class="h-full flex items-center justify-center w-full px-2"
              @click="query = ''"
            >
              <MdiIcon :icon="mdiClose" class="size-5" />
            </button>
          </div>
        </div>
      </transition>
      <div v-if="Object.keys(groupedList).length > 0" class="flex flex-col gap-6">
        <div v-for="(groupUserClients, group) in groupedList" :key="group">
          <h2 class="font-bold text-csBlue-800 mb-2">
            {{ group }}
          </h2>
          <div class="rounded overflow-hidden shadow border border-gray-200 divide-y divide-gray-200">
            <TeamMember
              v-for="data in groupUserClients"
              :key="data.user.id"
              :data="data"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="border border-dashed border-gray-300 px-4 py-6 text-center rounded text-xs mb-4 mt-2 text-gray-500 flex flex-col items-center justify-center"
      >
        <p v-if="query !== ''">
          {{ $t('people.empty_search') }}
        </p>
        <p v-else-if="groupBy === 'team'">
          {{ $t('people.empty_team') }}
        </p>
        <p v-else>
          {{ $t('people.empty') }}
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="stylus" scoped>
  .search-enter-from,
  .search-leave-to
    opacity 0
    max-height 0

  .search-enter-to,
  .search-leave-from
    opacity 1
    max-height 100px

  .search-enter-active, .search-leave-active
    transition .2s ease-out
    transition-property max-height, opacity
</style>
