import { setupAxios } from '@/bootstrap/http'
import store, { key } from './store'
import router, { setupRouter } from '@/bootstrap/router'
import { setupSentry } from '@/bootstrap/sentry'
import { subscribe as subscribeWebsocketEvents } from '@/bootstrap/websocket'
import { StatusBar } from '@capacitor/status-bar'
import { setupCapacitorApp } from '@/bootstrap/capacitor'
import { logError, setVH } from '@/common/utils'
import VWave from 'v-wave'
import { createApp } from 'vue'
import App from './App.vue'

import './styles.css'
import { initBrowserDummyToken, initFirebaseNative } from '@/bootstrap/firebase'
import { useConfig } from '@/config'
import { restoreClientState } from '@/store/client.state'
import { restoreUserState } from '@/store/user.state'
import { ifNative, ifNativeElse } from '@/capacitor'
import { setupI18N } from '@/bootstrap/i18n'
import { Locale } from '@/types'

ifNative(() => {
  StatusBar.setBackgroundColor({ color: '#203860' })
})

async function main () {
  const config = await useConfig()

  const i18n = await setupI18N()

  await Promise.all([
    await restoreClientState(store),
    await restoreUserState(store)
  ])

  setVH()

  await subscribeWebsocketEvents(store, router)

  ifNativeElse(() => {
    initFirebaseNative(store, router)
  }, () => {
    initBrowserDummyToken()
  })

  const app = createApp(App)

  if (config.backend) {
    // @ts-ignore
    setupAxios(store, router, i18n.global.locale.value as Locale, config.backend, config.version)
  }

  await setupSentry(app)
  await setupRouter(store)
  setupCapacitorApp(router, store)

  app
    .use(router)
    .use(i18n)
    .use(store, key)
    .use(VWave, {})

  app.mount('#app')

  document.documentElement.classList.remove('booting')
}

main()

// Log global errors to Vuex
window.onerror = (message, source, lineno, colno, error) => {
  logError('UNHANDLED ERROR:', message, source, lineno, colno, error)
}
